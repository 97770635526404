import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /* User http://127.0.0.1:8000/
    https://anesthesia.routingflow.com/crudgen/public/
    */
    siteURL: 'https://anesthesia.routingflow.com/crudgen/public/',
    userName: null,
    userID:null,
    userEmail: null,
    userAvatar: null,

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    },
    authUser (state, userData){
      state.idToken = userData.idToken
      state.userID = userData.userID
    },
    clearAuthData(state){
      state.idToken = null;
      state.userID = null;
    },
    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'
      let isShow
      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    }
  },
  actions: {
      tryAutoLogin ({commit}){
          const Token = localStorage.getItem('token');
          const userID = 2;
          if(!Token){
            return;
          }
          console.log('tryautoLogin');
          commit('authUser', {
            idToken: Token,
            userID: userID
          })
      },
      login({commit}, authData){
        var baseUrl = this.state.siteURL + '/api/client/login.php';
        axios.post(baseUrl, {
          email: authData.email,
          password:authData.password
        }).then(res => {
          console.log(res);
            commit('authUser', {
              idToken: res.data.token,
              userID: res.data.userid
            })
            localStorage.setItem('token', res.data.token);
            router.push("/");
        })
        .catch(error => {
          console.log(error);
        })
      },/// Login Function ends
      logout({commit}){
          commit('clearAuthData')
      }
  }
})
